// src/pages/DashboardHome.js
import React, { useState, useEffect } from 'react';
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaUserTimes,
  FaArrowAltCircleUp,
  FaBirthdayCake,
  FaPlaneDeparture,
  FaHeartbeat,
  FaUserClock,
  FaBabyCarriage,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Reverse-geocode function
const reverseGeocode = async (pointStr) => {
  if (!pointStr || !pointStr.startsWith('POINT(')) return null;
  const coordsStr = pointStr.slice(6, -1);
  const [lon, lat] = coordsStr.split(' ');
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lon}`
    );
    if (!response.ok) return null;
    const data = await response.json();
    return data.display_name || null;
  } catch (error) {
    console.error('Reverse geocoding error:', error);
    return null;
  }
};

// Helper: Decode JWT
const decodeJWT = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
};

// Helper: Show first part of address
const getShortAddress = (fullAddress) => {
  if (!fullAddress) return '';
  return fullAddress.split(',')[0].trim();
};

// Helper: CSS class for discrepancy
const getDiscrepancyClass = (discrepancy) => {
  if (!discrepancy || discrepancy === 'N/A') return 'text-muted';
  if (discrepancy.toLowerCase().includes('late')) return 'text-danger';
  if (discrepancy.toLowerCase().includes('early')) return 'text-success';
  return 'text-muted';
};

// Helper: Work hours percentage
const getWorkHoursPercentage = (hours) => {
  const maxHours = 8;
  const pct = (hours / maxHours) * 100;
  return pct > 100 ? 100 : pct;
};

// Helper: Format date
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
};

// Helper: Get leave type icon
const getLeaveTypeIcon = (leaveType) => {
  switch (leaveType.toLowerCase()) {
    case 'vacation': return <FaPlaneDeparture className="me-2 text-info" />;
    case 'sick': return <FaHeartbeat className="me-2 text-danger" />;
    case 'personal': return <FaUserClock className="me-2 text-primary" />;
    case 'maternity': return <FaBabyCarriage className="me-2 text-pink" />;
    default: return <FaUserClock className="me-2 text-muted" />;
  }
};

// Helper: Get status background color
const getStatusBgClass = (status) => {
  switch (status.toLowerCase()) {
    case 'pending manager': return 'bg-warning bg-opacity-10 border-warning';
    case 'approved': return 'bg-success bg-opacity-10 border-success';
    case 'rejected': return 'bg-danger bg-opacity-10 border-danger';
    default: return 'bg-light border-muted';
  }
};

const DashboardHome = ({ token }) => {
  const [dailyStats, setDailyStats] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [addressMap, setAddressMap] = useState({});

  const user = token ? decodeJWT(token) : null;

  // Fetch dashboard stats
  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}dashboard_stats`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error(`HTTP error ${response.status}`);
        const data = await response.json();
        setDailyStats(data.dailyStats || []);
        setEmployees(data.employees || []);
        setError(''); // Clear error on success
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    if (token) fetchDashboardData();
  }, [token]);

  // Fetch leave requests with polling
  useEffect(() => {
    const fetchLeaveRequests = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}leave_requests`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error(`HTTP error ${response.status}`);
        const data = await response.json();
        const pendingRequests = Array.isArray(data)
          ? data.filter(req => req.status && req.status.toLowerCase().includes('pending'))
          : [];
        setLeaveRequests(pendingRequests);
        setError(''); // Clear error on success
      } catch (err) {
        console.error('Error fetching leave requests:', err);
        setError(err.message);
      }
    };

    if (token && user) {
      fetchLeaveRequests();
      const interval = setInterval(fetchLeaveRequests, 30000); // Poll every 30 seconds
      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [token, user]);

  // Fetch upcoming birthdays
  useEffect(() => {
    const fetchUpcomingBirthdays = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}upcoming_birthdays`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error(`HTTP error ${response.status}`);
        const data = await response.json();
        setUpcomingBirthdays(data);
        setError(''); // Clear error on success
      } catch (err) {
        console.error('Error fetching upcoming birthdays:', err);
        setError(err.message);
      }
    };
    if (token) fetchUpcomingBirthdays();
  }, [token]);

  // Reverse-geocode employee locations
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const newMap = { ...addressMap };
        for (const emp of employees) {
          if (emp?.location?.startsWith('POINT(') && !newMap[emp.id]) {
            const fullAddr = await reverseGeocode(emp.location);
            newMap[emp.id] = fullAddr || '';
          }
        }
        setAddressMap(newMap);
      } catch (err) {
        console.error('Error in reverse geocoding:', err);
      }
    };
    if (employees.length > 0) fetchAddresses();
  }, [employees]);

  if (loading) {
    return <div className="container mt-4"><p>Loading dashboard data...</p></div>;
  }

  if (error) {
    return (
      <div className="container mt-4">
        <div className="alert alert-danger"><strong>Error:</strong> {error}</div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      {/* Today's Stats Cards */}
      <div className="row mb-4">
        <div className="col">
          <h5>Today's Stats</h5>
        </div>
      </div>
      <div className="row">
        {dailyStats.length > 0 ? (
          dailyStats.map((stat, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-3" key={index}>
              <div className={`card border-${stat.color} h-100`}>
                <div className="card-body d-flex align-items-center">
                  <div className={`fs-2 me-3 text-${stat.color}`}>
                    {stat.icon === 'onTime' ? <FaCheckCircle /> :
                      stat.icon === 'late' ? <FaExclamationTriangle /> :
                        stat.icon === 'early' ? <FaArrowAltCircleUp /> :
                          <FaUserTimes />}
                  </div>
                  <div>
                    <h6 className="mb-1">{stat.label}</h6>
                    <h4 className="mb-0">{stat.value}</h4>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col"><p>No stats available for today.</p></div>
        )}
      </div>

      {/* Pending Leave Requests Section */}
      <div className="row mt-4">
        <div className="col">
          <h5>{user?.role === 'employee' ? 'My Pending Requests' : 'Pending Leave Requests'}</h5>
          {leaveRequests.length === 0 ? (
            <p className="text-muted">No pending leave requests.</p>
          ) : (
            <div className="row">
              {leaveRequests.slice(0, 3).map((req) => (
                <div className="col-md-4 col-sm-12 mb-3" key={req.request_id}>
                  <Link
                    to={user?.role === 'employee' ? '/leave-dashboard' : '/leave-approval'}
                    className={`card h-100 text-decoration-none border ${getStatusBgClass(req.status)}`}
                    style={{ transition: 'all 0.3s', cursor: 'pointer' }}
                    onMouseEnter={(e) => e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)'}
                    onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
                  >
                    <div className="card-body d-flex align-items-center">
                      {getLeaveTypeIcon(req.leave_type)}
                      <div>
                        <h6 className="mb-1">Request #{req.request_id}</h6>
                        {user?.role !== 'employee' && (
                          <small className="d-block text-primary">{req.employee_name}</small>
                        )}
                        <small className="d-block">{formatDate(req.start_date)} - {formatDate(req.end_date)}</small>
                        <small className="d-block text-muted">{req.leave_type}</small>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
              {leaveRequests.length > 3 && (
                <div className="col-12 mt-2">
                  <p className="text-muted">
                    Showing 3 of {leaveRequests.length} pending requests.{' '}
                    <Link to={user?.role === 'employee' ? '/leave-dashboard' : '/leave-approval'}>
                      View all
                    </Link>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Upcoming Birthdays Section */}
      <div className="row mt-4">
        <div className="col">
          <h5>Upcoming Birthdays (Next 30 Days)</h5>
          {upcomingBirthdays.length === 0 ? (
            <p>No upcoming birthdays in the next 30 days.</p>
          ) : (
            <div className="list-group">
              {upcomingBirthdays.map((emp) => (
                <div className="list-group-item d-flex align-items-center" key={emp.employee_id}>
                  <FaBirthdayCake className="me-2 text-primary" />
                  <div>
                    <strong>{emp.name}</strong>
                    <br />
                    <small>Birthday: {formatDate(emp.date_of_birth)}</small>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Time Tracking Stats Table */}
      <div className="row mt-4">
        <div className="col">
          <h5>Today's Time Tracking Stats</h5>
          <div className="table-responsive">
            <table className="table align-middle">
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Department</th>
                  <th>Location</th>
                  <th>Work Hours Today</th>
                  <th>Clock In</th>
                  <th>Discrepancy</th>
                </tr>
              </thead>
              <tbody>
                {employees.length > 0 ? (
                  employees.map((emp) => {
                    let finalLocation = emp.location || 'N/A';
                    if (emp.location?.startsWith('POINT(')) {
                      finalLocation = addressMap[emp.id] || 'Fetching address...';
                    }
                    const shortAddr = getShortAddress(finalLocation);
                    return (
                      <tr key={emp.id}>
                        <td>
                          <strong>{emp.name || 'Unknown'}</strong>
                          <br />
                          <span className="text-muted">{emp.role || 'N/A'}</span>
                        </td>
                        <td>{emp.department || 'N/A'}</td>
                        <td>{shortAddr}</td>
                        <td style={{ width: '180px' }}>
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 me-2">
                              <div className="progress" style={{ height: '6px' }}>
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  style={{ width: `${getWorkHoursPercentage(emp.workHours || 0)}%` }}
                                  aria-valuenow={emp.workHours || 0}
                                  aria-valuemin="0"
                                  aria-valuemax="8"
                                />
                              </div>
                            </div>
                            <small className="text-muted">{emp.workHours || 0} hrs</small>
                          </div>
                        </td>
                        <td>
                          {emp.clockInType === 'in office' ? (
                            <span className="badge bg-success">in office</span>
                          ) : (
                            <span className="badge bg-warning">remote</span>
                          )}
                        </td>
                        <td>
                          {emp.discrepancy && emp.discrepancy !== 'N/A' ? (
                            <span className={getDiscrepancyClass(emp.discrepancy)}>
                              {emp.discrepancy}
                            </span>
                          ) : (
                            <span className="text-muted">N/A</span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">No time tracking data available for today.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;